import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.setupMutationObserver()
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  setupMutationObserver() {
    const config = { attributes: true, attributeFilter: ['class'] }

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const isVisible = !this.element.classList.contains('translate-x-full')
          if (isVisible) {
            this.onDrawerShown()
          }
        }
      })
    })

    this.observer.observe(this.element, config)
  }

  onDrawerShown() {
    window.cartViewed();
  }
}
