import { Controller } from "@hotwired/stimulus"

export default class GalleryController extends Controller {
  static targets = [ 'preview', 'image', 'control', 'container' ]

  connect() {
    this.index = 0
    const isContainerScrollable = this.containerTarget.scrollHeight > this.containerTarget.clientHeight

    // Toggle button visibility if the gallery preview is scrollable
    if (isContainerScrollable) {
      this.controlTarget.classList.toggle('hidden')
    }
  }

  controlClick(event) {
    const parentNode = event.target.parentNode
    const imgSrc = parentNode.dataset.src
    this.index = parseInt(parentNode.dataset.index);
    const imgIndex = parseInt(parentNode.dataset.index)
    this.imageTarget.src = imgSrc;

    this.previewTargets.forEach((preview, index) => {
      if (index === imgIndex) {
        preview.classList.add('active');
      } else {
        preview.classList.remove('active');
      }
    });
  }

  nextImage() {
    if (this.previewTargets.length === this.index + 1) return;

    const nextIndex = this.index + 1
    const imgSrc = this.previewTargets[nextIndex].dataset.src;
    this.index = nextIndex

    this.imageTarget.src = imgSrc;
    this.previewTargets.forEach((preview, index) => {
      if (index === this.index) {
        preview.classList.add('active');
      } else {
        preview.classList.remove('active');
      }
    });
  }
}
