import { Controller } from "@hotwired/stimulus"

export default class OffersController extends Controller {
  static targets = ['banner']
  connect() {
    document.addEventListener('scroll', this.bannerListener)
  }

  disconnect() {
    document.removeEventListener('scroll', this.bannerListener)
  }

  bannerListener() {
    const page = document.getElementById('offers-page')
    const position = page.getBoundingClientRect()
    if (position.y < 0) {
      document.getElementById('bottom-banner').classList.remove('md:hidden')
    } else {
      document.getElementById('bottom-banner').classList.add('md:hidden')
    }
  }
}
