import { Controller } from "@hotwired/stimulus";

export default class PriceRangeFilterController extends Controller {
  static targets = ['form', 'minPrice', 'maxPrice', 'minPriceInput', 'maxPriceInput']

  connect() {
    this.minPriceInputTarget.addEventListener('keydown', this.submitForm.bind(this))
    this.maxPriceInputTarget.addEventListener('keydown', this.submitForm.bind(this))

    const rangeInput = document.querySelectorAll(".range-input input")

    rangeInput.forEach((input) => {
      input.addEventListener("input", this.updateRange.bind(this));
      input.addEventListener('mouseup', this.submitForm.bind(this))
    })
  }

  disconnect() {
    this.minPriceInputTarget.removeEventListener('keydown', this.submitForm.bind(this))
    this.maxPriceInputTarget.removeEventListener('keydown', this.submitForm.bind(this))

    const rangeInput = document.querySelectorAll(".range-input input")

    rangeInput.forEach((input) => {
      input.removeEventListener("input", this.updateRange.bind(this));
      input.removeEventListener('mouseup', this.submitForm.bind(this))
    })
  }

  updateRange(e) {
    const rangeInput = document.querySelectorAll(".range-input input")
    const range = document.querySelector(".slider .progress");

    let priceGap = 100;
    let minVal = parseInt(rangeInput[0].value);
    let maxVal = parseInt(rangeInput[1].value);

    if (maxVal - minVal < priceGap) {
      if (e.target.className === "range-min") {
        rangeInput[0].value = maxVal - priceGap;
      } else {
        rangeInput[1].value = minVal + priceGap;
      }
    } else {
      this.minPriceInputTarget.value = minVal.toFixed(2);
      this.maxPriceInputTarget.value = maxVal.toFixed(2);

      range.style.left = (minVal / rangeInput[0].max) * 100 + "%";
      range.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + "%";
    }
  }

  submitForm(e) {
    let maxPriceFromForm = parseFloat(this.maxPriceInputTarget.value) * 100
    let minPriceFromForm = parseFloat(this.minPriceInputTarget.value) * 100

    if (e.key === 'Enter' || e.type === 'blur' || e.type === 'mouseup') {
      this.minPriceTarget.value = minPriceFromForm
      this.maxPriceTarget.value = maxPriceFromForm
      this.formTarget.submit()
    }
  }
}
