import { Controller } from "@hotwired/stimulus"

export default class AlertController extends Controller {

    get button() {
        return this.element.querySelector('[aria-label="Close"]')
    }

    connect() {
        this.button?.addEventListener('click', this.remove.bind(this));
    }

    disconnect () {
        this.button?.removeEventListener('click', this.remove.bind(this));
    }

    remove() {
        this.element.remove();
    }

}