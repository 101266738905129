import { Controller } from "@hotwired/stimulus"

export default class TabsController extends Controller {

    navigate(e) {
        const tabs = this.element.querySelectorAll('[role="tab"]')
        tabs?.forEach(tab => {
            tab.ariaSelected = tab === e.target ? 'true' : 'false';
        })
    }

}