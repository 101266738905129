import { Controller } from "@hotwired/stimulus"

export default class EmailButtonController extends Controller {


    copy() {

        if (!navigator.clipboard) {
            console.log('Clipboard API not available')
            return;
        }

        const email = 'help@shopearl.com';
        const button = this.element;
        const originalText = button.innerText;

        navigator.clipboard.writeText(email).then(function() {
            button.disabled = true;
            button.innerText = 'Copied to clipboard!';

            setTimeout(function() {
                button.innerText = originalText;
                button.disabled = false;
            }, 2000);
        }).catch(function(error) {
            console.error('Could not copy text: ', error);
        });
    }


}