import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class SearchableFilter extends Controller {

  static targets = ['form', 'search']

  connect() {
    this.formTarget.addEventListener('submit', this.formSubmitHandler.bind(this))
  }

  formSubmitHandler(event) {
    event.preventDefault();
    const url = event.target.action;
    const search = this.searchTarget.value;
    this.fetchResults(url, search)
  }

  async fetchResults(url = '', search = '') {
    try {
      const { data } = await axios.get(`${url}.json`, {
        params: { search }
      });
      this.updateResults(data, search, url)
    } catch (error) {
      console.error('Failed to load: ', error)
    }
  }

  updateResults(data, search, url) {
    const model = this.formTarget.dataset.model
    const resultsContainerId = this.formTarget.dataset.id
    const resultsContainer = document.getElementById(resultsContainerId)

    // hide old results
    resultsContainer.childNodes.forEach(node => {
      const isLink = node.tagName === 'A'

      if (node.classList?.contains('no-results')) {
        node.remove()
      }

      if (isLink && node.classList.contains('input-group')) {
        node.classList.toggle('input-group')
        node.classList.toggle('hidden')
      }
    })

    if (!data.length && !search.length) {
      resultsContainer.childNodes.forEach(node => {
        const isLink = node.tagName === 'A'
        if (isLink && node.classList.contains('original')) {
          node.classList.toggle('input-group')
          node.classList.toggle('hidden')
        }
      })
    }

    // append new results
    data.forEach(option => {
      const requestUrl =  new URL(window.location.href);
      requestUrl.searchParams.set(model, option.value)
      const newLink = document.createElement('a');
      const newCheckbox = document.createElement("INPUT");
      newCheckbox.setAttribute("type", "checkbox");
      var linkLabel = document.createTextNode(option.value);
      newLink.classList.add('input-group')
      newCheckbox.classList.add('visible-checkbox')
      newLink.href = requestUrl
      newLink.appendChild(newCheckbox);
      newLink.appendChild(linkLabel);
      resultsContainer.appendChild(newLink)
    })


    if (!data.length && search.length) {
      const noResults = document.createElement('div');
      const buttonElem = document.createElement('button');
      var text = document.createTextNode('No Results for ');
      var queryText = document.createTextNode(` “${search}”`);
      var buttonText = document.createTextNode('× Clear Search');
      var queryElem = document.createElement('span');
      buttonElem.setAttribute("class", "btn-link-primary text-xs block mt-2 w-full");
      buttonElem.onclick = () => {
        this.searchTarget.value = ''
        this.fetchResults(url, '');
      }
      noResults.setAttribute("class", "no-results text-sm text-center");
      queryElem.setAttribute("class", "text-secondary pl-1");
      buttonElem.appendChild(buttonText);
      noResults.appendChild(text);
      queryElem.appendChild(queryText);
      noResults.appendChild(queryElem);
      noResults.appendChild(buttonElem);
      resultsContainer.appendChild(noResults)
    }

  }


}
