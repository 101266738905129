import { Controller } from "@hotwired/stimulus"

class ShiftModal extends Modal {

    // Custom extension to protect against errors i was
    // seeing when backdrop would throw an npe on remove()
    // Honestly doesnt make sense after looking at the code, but adding the
    // safety check has resolved the issue :shrug:
    _destroyBackdropEl() {
        if (!this._isHidden && this._backdropEl) {
            this._backdropEl?.remove();
            this._backdropEl = null
        }
    }

}

export default class ModalController extends Controller {

    // Note - some of this logic was sourced from this issue thread
    // https://github.com/themesberg/flowbite/issues/796

    get autoLaunch() {
        return this.element.dataset.autoLaunch?.toLowerCase() === 'true'
    }

    connect() {
        this.modal = new ShiftModal(this.element);
        if (this.autoLaunch) {
            this.show();
        }
    }

    disconnect() {
        this.hide();
    }

    show() {
        this.modal?.show();
    }

    hide() {
        this.modal.hide();
        this.modal.destroy();
    }

}