import { Controller } from "@hotwired/stimulus"

export default class InputCounterController extends Controller {
  static targets = ['increment', 'decrement', 'input']

  increment() {
    const currentValue = parseInt(this.inputTarget.value)
    this.updateCounter(currentValue + 1)
  }

  decrement() {
    const currentValue = parseInt(this.inputTarget.value)
    this.updateCounter(currentValue - 1)
  }


  updateCounter(value) {
    if (value >= this.inputTarget.min) {
      this.inputTarget.value = value
    }
  }
}
