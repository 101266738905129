import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'makes', 'years', 'models']

  connect() {
    // This will allow us to use turbo streams with a GET request.
    // This is needed because when using just the turbo frame the modal backdrop unmounts from the page
    this.element.addEventListener(
      "turbo:before-fetch-request",
      this.addTurboStreamHeaders.bind(this)
    )
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:before-fetch-request",
      this.addTurboStreamHeaders
    )
  }

  addTurboStreamHeaders(event) {
    const {headers} = event.detail.fetchOptions || {}

    if (headers) {
      headers.Accept = ["text/vnd.turbo-stream.html", headers.Accept].join(", ")
    }
  }


  submitForm() {
    this.formTarget.requestSubmit()
  }

  createVehicle() {
   const url = window.location.href.split('?')[0]
    const yearSelected = this.yearsTarget.value
    const makeSelected = this.makesTarget.value
    const modelSelected = this.modelsTarget.value
    window.location.replace(`${url}?year=${yearSelected}&make=${makeSelected}&model=${modelSelected}`);
  }

}
