import { Controller } from "@hotwired/stimulus"

export default class ScrollController extends Controller {
  static targets = ['element']

  initialize() {
    this.scrollPosition = 0
    this.fixedScrollEnabled = false;
  }

  connect() {
    document.addEventListener('turbo:load', this.enableScrollPosition.bind(this))
    document.addEventListener('turbo:before-visit', this.setScrollPosition.bind(this))
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.enableScrollPosition.bind(this))
    document.removeEventListener('turbo:before-visit', this.setScrollPosition.bind(this))
  }

  enableScrollPosition() {
    if (this.elementTarget) {
      this.elementTarget.addEventListener('click', () => {
        this.fixedScrollEnabled =  true
      })
    }

    if (this.fixedScrollEnabled && this.scrollPosition > 0) {
      window.scrollTo(0, this.scrollPosition)
      this.fixedScrollEnabled = false
      this.scrollPosition = 0
    }
  }

  setScrollPosition() {
    if (this.fixedScrollEnabled) {
      this.scrollPosition = window.scrollY;
    }
  }
}
