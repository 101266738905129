import { Controller } from "@hotwired/stimulus"

export default class ShipmentController extends Controller {

    static targets = []

    toggle() {
        this.element.querySelector('#panel-content').classList.toggle('hidden')
        this.element.querySelector('#panel-content').classList.toggle('block')
        this.element.querySelector('button').setAttribute('aria-expanded', this.expanded)
    }

    get expanded() {
        return this.element.querySelector('button').getAttribute('aria-expanded') === 'true'
    }

}