import { Controller } from "@hotwired/stimulus"

export default class FormGroupController extends Controller {

    get form() {
        return this.element.closest('form');
    }

    get formFields () {
        return Array.from(this.form.elements)
    }

    get firstInvalidField () {
        return this.formFields.find(field => !field.checkValidity())
    }

    connect() {
        this.form.setAttribute('novalidate', true)
        this.form.addEventListener('blur', this.onBlur, true)
        this.form.addEventListener('submit', this.onSubmit)
        this.form.addEventListener('ajax:beforeSend', this.onSubmit)
        this.initForm();
    }

    disconnect () {
        this.form.removeEventListener('blur', this.onBlur)
        this.form.removeEventListener('submit', this.onSubmit)
        this.form.removeEventListener('ajax:beforeSend', this.onSubmit)
    }

    initForm() {
        this.form.reset();
        this.formFields.forEach(field => {
            if (field.required) {
                const label = field.closest('.form-control').querySelector('label')

                const asteriskSpan = document.createElement('span');
                asteriskSpan.className = 'text-secondary-dk';
                asteriskSpan.textContent = '*';

                label.appendChild(asteriskSpan);
            }
        })
    }

    onBlur = (event) => {
        this.validateField(event.target)
    }

    onSubmit = (event) => {
        if (!this.validateForm()) {
            event.preventDefault()
            this.firstInvalidField.focus()
        }
    }

    validateForm () {
        let isValid = true
        // Not using `find` because we want to validate all the fields
        this.formFields.forEach((field) => {
            if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
        })
        return isValid
    }

    validateField (field) {
        if (!this.shouldValidateField(field)) {
            return true
        }
        const isValid = field.checkValidity()
        field.classList.toggle('invalid', !isValid)
        this.refreshErrorForInvalidField(field, isValid)
        return isValid
    }

    shouldValidateField (field) {
        return !field.disabled && !['file', 'reset', 'submit', 'button'].includes(field.type)
    }

    refreshErrorForInvalidField (field, isValid) {
        if (!isValid) {
            this.showErrorForInvalidField(field)
        } else {
            this.removeExistingErrorMessage(field)
        }
    }

    removeExistingErrorMessage (field) {
        if (field.classList.contains('invalid:ring-secondary')) {
            field.classList.remove('invalid:ring-secondary')
        }
        const fieldContainer = field.closest('.form-control')
        if (!fieldContainer) { return; }
        const existingErrorMessageElement = fieldContainer.querySelector('.form-control-error')
        if (existingErrorMessageElement) {
            existingErrorMessageElement.classList.add('hidden');
            existingErrorMessageElement.innerHTML = "";
        }
    }

    showErrorForInvalidField (field) {
        if (!field.classList.contains('invalid:ring-secondary')) {
            field.classList.add('invalid:ring-secondary')
        }

        const fieldContainer = field.closest('.form-control')
        if (!fieldContainer) { return; }
        const existingErrorMessageElement = fieldContainer.querySelector('.form-control-error')
        if (existingErrorMessageElement) {
            existingErrorMessageElement.classList.remove('hidden');
            existingErrorMessageElement.innerHTML = field.validationMessage;
        }

        // field.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
    }

    buildFieldErrorHtml (field) {
        return `<p class="form-control-error mt-1 text-xxs font-medium text-secondary-dk">${field.validationMessage}</p>`
    }

}