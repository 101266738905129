import { Controller } from "@hotwired/stimulus"

export default class CartHeaderController extends Controller {

  initialize() {
    this.purchaseCart = false;
  }

  connect() {
    // This supports the cart drawer to open post cart item creation
    this.element.addEventListener('turbo:submit-end', this.cartSubmitListener.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.cartSubmitListener)
  }

  toggleCartDrawer() {
    document.getElementById('cart-drawer-action').click()
  }

  purchaseNow() {
    this.purchaseCart = true;
  }

  cartSubmitListener(event) {
    const url = window.location.origin;
    const checkoutButton = document.getElementById('proceed-checkout');

    // Open cart drawer after form submit:
    if (event.target.id === 'new_cart_item') {
      if (event.detail.success) {
        if (this.purchaseCart) {
          if (!checkoutButton || !checkoutButton?.disabled) {
            window.location.replace(`${url}/checkouts/new`);
          } else {
            this.toggleCartDrawer()
          }
        } else {
          this.toggleCartDrawer()
        }
      }
    }

    // Redirect to checkout page after cart validation:
    if (event.target.action.includes('/cart/checkout')) {
      if (event.detail.success) {
        window.location.replace(`${url}/checkouts/new`);
      }
    }
  }
}
