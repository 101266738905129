import { Controller } from "@hotwired/stimulus"

export default class FormImagePickerController extends Controller {

    static targets = ["previews", "dropzone", "fileInput"]

    handleFiles(event) {
        this.addPreviews(event.target.files);
    }

    handleDragOver(event) {
        event.preventDefault()
        this.dropzoneTarget.classList.add("bg-gray-200")
    }

    handleDrop(event) {
        event.preventDefault()
        this.dropzoneTarget.classList.remove("bg-gray-200")
        const files = event.dataTransfer.files
        this.addFilesToInput(files);
        this.addPreviews(files);
    }

    addFilesToInput(files) {
        const dataTransfer = new DataTransfer()
        Array.from(files).forEach(file => {
            dataTransfer.items.add(file)
        })

        // Merge the new files with the existing files in the input
        Array.from(this.fileInputTarget.files).forEach(file => {
            dataTransfer.items.add(file)
        })

        this.fileInputTarget.files = dataTransfer.files
    }

    addPreviews(files) {
        Array.from(files).forEach(file => {
            const reader = new FileReader()
            reader.onload = (e) => {
                const imageUrl = e.target.result
                this.addPreview(imageUrl)
            }
            reader.readAsDataURL(file)
        })
    }

    addPreview(imageUrl) {
        const imageUploadContainer = document.createElement('div')
        imageUploadContainer.classList.add('flex', 'justify-start', 'items-center', 'gap-6', 'mt-4')

        const img = document.createElement('img')
        img.src = imageUrl
        img.classList.add('w-auto', 'h-16', 'rounded-md')

        const imgContainer = document.createElement('div');
        imgContainer.classList.add('flex', 'w-16');
        imgContainer.appendChild(img);

        const removeButtonLabel = document.createElement('span');
        removeButtonLabel.classList.add('text-base', 'font-medium')
        removeButtonLabel.innerHTML = 'Remove image'

        const removeButtonIconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        removeButtonIconPath.setAttribute('d', 'M5.76 13.5 9 10.26l3.24 3.24 1.26-1.26L10.26 9l3.24-3.24-1.26-1.26L9 7.74 5.76 4.5 4.5 5.76 7.74 9 4.5 12.24zM9 18a8.8 8.8 0 0 1-3.51-.709 9.1 9.1 0 0 1-2.858-1.924A9.1 9.1 0 0 1 .71 12.51 8.8 8.8 0 0 1 0 9q0-1.867.709-3.51a9.1 9.1 0 0 1 1.923-2.858A9.1 9.1 0 0 1 5.49.71 8.8 8.8 0 0 1 9 0q1.867 0 3.51.709a9.1 9.1 0 0 1 2.858 1.923A9.1 9.1 0 0 1 17.29 5.49 8.8 8.8 0 0 1 18 9a8.8 8.8 0 0 1-.709 3.51 9.1 9.1 0 0 1-1.924 2.858 9.1 9.1 0 0 1-2.857 1.923A8.8 8.8 0 0 1 9 18m0-1.8q3.015 0 5.108-2.092Q16.199 12.014 16.2 9q0-3.016-2.092-5.107Q12.014 1.8 9 1.8q-3.016 0-5.107 2.093Q1.8 5.985 1.8 9t2.093 5.108Q5.985 16.199 9 16.2');
        removeButtonIconPath.setAttribute('fill', 'currentColor');

        const removeButtonIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        removeButtonIcon.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', 'http://www.w3.org/2000/svg');
        removeButtonIcon.setAttribute('viewBox','0 0 18 18');
        removeButtonIcon.setAttribute('fill','none');
        removeButtonIcon.classList.add('w-5', 'h-5');
        removeButtonIcon.appendChild(removeButtonIconPath);

        const removeButton = document.createElement('button')
        removeButton.type = 'button'
        removeButton.classList.add('flex', 'justify-start', 'items-center', 'gap-2', 'text-primary')
        removeButton.addEventListener('click', event => {
            event.preventDefault();
            event.stopImmediatePropagation();
            imageUploadContainer.remove();
        })
        removeButton.appendChild(removeButtonLabel);
        removeButton.appendChild(removeButtonIcon);

        imageUploadContainer.appendChild(imgContainer)
        imageUploadContainer.appendChild(removeButton)
        this.previewsTarget.appendChild(imageUploadContainer)
    }

}