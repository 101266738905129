import { Controller } from "@hotwired/stimulus"

export default class FormNumberStepperController extends Controller {
    static targets = ["input"]

    get currentValue() {
        return parseInt(this.inputTarget.value, 10) || 0
    }

    get minValue() {
        return parseInt(this.inputTarget.min, 10)
    }

    get maxValue() {
        return parseInt(this.inputTarget.max, 10)
    }

    decrease(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        if (isNaN(this.minValue)) {
            return this.inputTarget.value = this.currentValue - 1
        }
        this.inputTarget.value = Math.max(this.currentValue - 1, this.minValue)
    }

    increase(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        if (isNaN(this.maxValue)) {
            return this.inputTarget.value = this.currentValue + 1
        }
        this.inputTarget.value = Math.min(this.currentValue + 1, this.maxValue)
    }

}